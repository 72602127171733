// @flow
import { Record } from 'immutable';
import type { RecordFactory } from 'immutable';

import type { ApiColumnMapping } from 'models/DataUploadApp/types';

export type ColumnMappingProps = {
  columnType: string,
  datatype: string,
  ignoreColumn: boolean,
  match: string | void,
  name: string,
};

const ColumnMappingRecord: RecordFactory<ColumnMappingProps> = Record({
  columnType: undefined,
  datatype: undefined,
  ignoreColumn: undefined,
  match: undefined,
  name: undefined,
});

export default class ColumnMapping extends ColumnMappingRecord {
  static deserialize(data: ApiColumnMapping): ColumnMapping {
    return new ColumnMapping({
      columnType: data.columnType,
      datatype: data.datatype,
      ignoreColumn: data.ignoreColumn,
      match: data.match ? data.match : undefined,
      name: data.name,
    });
  }

  serialize(): ApiColumnMapping {
    return this.toObject();
  }

  columnType(): string {
    return this.get('columnType');
  }

  setColumnType<T>(value: string): T {
    return ((this.set('columnType', value): any): T);
  }

  name(): string {
    return this.get('name');
  }

  setName<T>(value: string): T {
    return ((this.set('name', value): any): T);
  }

  datatype(): string {
    return this.get('datatype');
  }

  setDatatype<T>(value: string): T {
    return ((this.set('datatype', value): any): T);
  }

  match(): string | void {
    return this.get('match');
  }

  setMatch<T>(value: string): T {
    return ((this.set('match', value): any): T);
  }

  ignoreColumn(): boolean {
    return this.get('ignoreColumn');
  }

  setIgnoreColumn<T>(value: boolean): T {
    return ((this.set('ignoreColumn', value): any): T);
  }
}
