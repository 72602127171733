// @flow
import * as React from 'react';
import { useLazyLoadQuery } from 'react-relay/hooks';

import AddDataModal from 'components/DataUploadApp/AddDataModal';
import DataUploadService from 'services/DataUploadApp/DataUploadService';
import HeaderBlock from 'components/DataUploadApp/HeaderBlock';
import SelfServeSource from 'models/DataUploadApp/SelfServeSource';
import SourceTable from 'components/DataUploadApp/SourceTable';
import useBoolean from 'lib/hooks/useBoolean';
import useFieldHierarchy from 'components/DataCatalogApp/common/hooks/aqt/useFieldHierarchy';
import useGroupingHierarchy from 'components/DataCatalogApp/common/hooks/aqt/useGroupingHierarchy';
import usePipelineTimes from 'components/DataUploadApp/usePipelineTimes';
import { DATAPREP_TYPE } from 'models/DataUploadApp/types';
import { noop } from 'util/util';
import type { DataStatusPageSelfServeQuery } from './__generated__/DataStatusPageSelfServeQuery.graphql';
import type { DataUploadModalState } from 'components/DataUploadApp/AddDataModal/useDataUploadModalContext';

type Props = {
  isSelfServeAdmin: boolean,
};

export default function DataStatusPage({
  isSelfServeAdmin,
}: Props): React.Node {
  const [modalVisible, showModal, hideModal] = useBoolean(false);
  const [initialSelfServeSource, setInitialSelfServeSource] =
    React.useState<SelfServeSource | void>(undefined);

  /* eslint-disable relay/must-colocate-fragment-spreads */
  const selfServeData = useLazyLoadQuery<DataStatusPageSelfServeQuery>(
    graphql`
      query DataStatusPageSelfServeQuery {
        selfServeSourceConnection: self_serve_source_connection {
          edges {
            node {
              sourceId: source_id
            }
          }
          ...SourceTable_selfServeSource
        }
        pipelineDatasourceConnection: pipeline_datasource_connection {
          ...DataprepSetUp_pipelineDatasourceConnection
        }
      }
    `,
    {},
  );
  /* eslint-enable relay/must-colocate-fragment-spreads */
  const existingSources = new Set(
    selfServeData.selfServeSourceConnection.edges.map(
      edge => edge.node.sourceId,
    ),
  );

  // Only use the first variable returned because we only need the hierarchy root.
  const fieldHierarchyRoot = useFieldHierarchy()[0];
  const dimensionHierarchyRoot = useGroupingHierarchy(false, true);

  const { lastPipelineRuntime, nextPipelineRuntime } = usePipelineTimes();

  const buildOnOpenModal =
    (source: SelfServeSource | void = undefined) =>
    () => {
      setInitialSelfServeSource(source);
      showModal();
    };
  // Opening the modal from the header block creates a new source, so the source is undefined.
  const onOpenModal = buildOnOpenModal();

  // Sources that had files uploaded and then cancelled need to clean those files up.
  const onCloseModal = (dataUploadState: DataUploadModalState | void) => {
    if (dataUploadState) {
      const filesToUpload = dataUploadState.fileSummaries
        .values()
        .filter(fileSummary => !fileSummary.fileSummaryId)
        .map(fileSummary => fileSummary.filePath);

      if (filesToUpload) {
        DataUploadService.cleanFiles(
          dataUploadState.sourceId,
          dataUploadState.sourceType === DATAPREP_TYPE,
          filesToUpload,
        );
      }
    }
    hideModal();
  };

  return (
    <div className="data-status-page min-full-page-height">
      <HeaderBlock
        isSelfServeAdmin={isSelfServeAdmin}
        lastPipelineRuntime={lastPipelineRuntime}
        nextPipelineRuntime={nextPipelineRuntime}
        onOpenModal={onOpenModal}
      />
      <SourceTable
        buildOnOpenModal={buildOnOpenModal}
        isSelfServeAdmin={isSelfServeAdmin}
        lastPipelineRuntime={lastPipelineRuntime}
      />
      {modalVisible && (
        <AddDataModal
          dimensionHierarchyRoot={dimensionHierarchyRoot}
          existingDataUploadSources={existingSources}
          fieldHierarchyRoot={fieldHierarchyRoot}
          // $FlowFixMe[incompatible-type]
          initialSelfServeSource={initialSelfServeSource}
          onCloseModal={onCloseModal}
          pipelineDatasourceRef={selfServeData.pipelineDatasourceConnection}
          refetchDataprepJobs={noop}
        />
      )}
    </div>
  );
}
