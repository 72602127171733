// @flow
import { Record } from 'immutable';
import type { RecordFactory } from 'immutable';

import DataprepJob from 'models/DataUploadApp/DataprepJob';
import type { ApiDataprepFlow } from 'models/DataUploadApp/types';

type DataprepFlowProps = {
  appendable: boolean,
  id: number,
  latestDataprepJob: DataprepJob | void,
  recipeId: string,
};

const DataprepFlowRecord: RecordFactory<DataprepFlowProps> = Record({
  appendable: undefined,
  id: undefined,
  latestDataprepJob: undefined,
  recipeId: undefined,
});

export default class DataprepFlow extends DataprepFlowRecord {
  static deserialize(data: ApiDataprepFlow): DataprepFlow {
    const { appendable, id, latestDataprepJob, recipeId } = data;

    return new DataprepFlow({
      appendable,
      id,
      recipeId,
      latestDataprepJob: latestDataprepJob
        ? DataprepJob.deserialize(latestDataprepJob)
        : undefined,
    });
  }

  serialize(): ApiDataprepFlow {
    const latestDataprepJob = this.latestDataprepJob();

    return {
      appendable: this.appendable(),
      id: this.id(),
      latestDataprepJob: latestDataprepJob
        ? latestDataprepJob.serialize()
        : undefined,
      recipeId: this.recipeId(),
    };
  }

  appendable(): boolean {
    return this.get('appendable');
  }

  id(): number {
    return this.get('id');
  }

  latestDataprepJob(): DataprepJob | void {
    return new DataprepJob(this.get('latestDataprepJob'));
  }

  recipeId(): string {
    return this.get('recipeId');
  }

  setApendable<T>(value: boolean): T {
    return ((this.set('appendable', value): any): T);
  }

  setId<T>(value: number): T {
    return ((this.set('id', value): any): T);
  }

  setLatestDataprepJob<T>(value: DataprepJob): T {
    return ((this.set('latestDataprepJob', value): any): T);
  }

  setRecipeId<T>(value: string): T {
    return ((this.set('recipeId', value): any): T);
  }
}
