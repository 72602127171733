// @flow
import { List, Record } from 'immutable';
import type { RecordFactory } from 'immutable';

import UnpublishedFieldCount from 'models/DataUploadApp/UnpublishedFieldCount';
import type { ApiPipelineDatasource } from 'models/DataUploadApp/types';

type PipelineDatasourceProps = {
  name: string,
  unpublishedFields: List<UnpublishedFieldCount>,
};

const PipelineDatasourceRecord: RecordFactory<PipelineDatasourceProps> = Record(
  {
    name: undefined,
    unpublishedFields: undefined,
  },
);

export default class PipelineDatasource extends PipelineDatasourceRecord {
  static deserialize(data: ApiPipelineDatasource): PipelineDatasource {
    return new PipelineDatasource({
      name: data.name,
      unpublishedFields: List(
        data.unpublishedFields.map(
          field =>
            new UnpublishedFieldCount({
              id: field.id,
            }),
        ),
      ),
    });
  }

  serialize(): Object {
    return {
      name: this.name(),
      unpublishedFields: this.unpublishedFields()
        .map(field => field.id())
        .toArray(),
    };
  }

  name(): string {
    return this.get('name');
  }

  unpublishedFields(): List<UnpublishedFieldCount> {
    return this.get('unpublishedFields');
  }

  setName<T>(value: string): T {
    return ((this.set('name', value): any): T);
  }

  setUnpublishedFields<T>(value: List<UnpublishedFieldCount>): T {
    return ((this.set('unpublishedFields', value): any): T);
  }
}
