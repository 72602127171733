// @flow
import { List } from 'immutable';

import APIService from 'services/APIService';
import PotionService from 'services/PotionService';
import SelfServeSource from 'models/DataUploadApp/SelfServeSource';
import createDataProvider from 'services/PotionService/hooks';
import type { HTTPService } from 'services/APIService';
import type { PotionItemStateProviderType } from 'services/PotionService/hooks';
import type {
  SourceDateRange,
  ApiDataprepFlow,
  ApiPipelineDatasource,
  ApiFileSummary,
} from 'models/DataUploadApp/types';

type CommonSerializedSelfServeSource = {
  dataprepFlow: ApiDataprepFlow | null,
  lastModified: string,
  pipelineDatasource: ApiPipelineDatasource,
  sourceId: string,
  sourceRange: SourceDateRange,
};

export type SerializedInputSelfServeSource = {
  $uri: string,
  fileSummaries: List<ApiFileSummary>,
  id: number,
  latestFileSummary: string,
  unpublishedDimensionsCount: number,
  ...CommonSerializedSelfServeSource,
};

export type SerializedOutputSelfServeSource = {
  ...CommonSerializedSelfServeSource,
};

class SelfServeSourceService extends PotionService<
  SelfServeSource,
  SerializedInputSelfServeSource,
  SerializedOutputSelfServeSource,
> {
  constructor(httpService: HTTPService, path: string) {
    const deserialize = (
      values: SerializedInputSelfServeSource,
    ): SelfServeSource => {
      return SelfServeSource.deserialize(values);
    };
    const serialize = (
      item: SelfServeSource,
    ): SerializedOutputSelfServeSource => {
      return item.serialize();
    };

    super(httpService, path, serialize, deserialize);
  }
}

const service = (new SelfServeSourceService(
  APIService,
  '/self_serve_source',
): SelfServeSourceService);
export default service;

const {
  PotionItemStateProvider: SelfServeSourcesProvider,
  usePotionItemStateContext: useSelfServeSourceStateContext,
}: PotionItemStateProviderType<
  SelfServeSource,
  SerializedInputSelfServeSource,
  SelfServeSourceService,
> = createDataProvider(service, { sort: { $uri: false } });
export { useSelfServeSourceStateContext, SelfServeSourcesProvider };
