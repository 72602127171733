// @flow
import { Record } from 'immutable';
import type { RecordFactory } from 'immutable';

type UnpublishedFieldCountProps = {
  id: number,
};

const UnpublishedFieldCountRecord: RecordFactory<UnpublishedFieldCountProps> = Record(
  {
    count: undefined,
  },
);

export default class UnpublishedFieldCount extends UnpublishedFieldCountRecord {
  id(): number {
    return this.get('id');
  }

  setID<T>(value: number): T {
    return ((this.set('id', value): any): T);
  }
}
