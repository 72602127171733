// @flow
import * as React from 'react';
import classNames from 'classnames';

import Button from 'components/ui/Button';
import DestructiveActionModal from 'components/common/DestructiveActionModal';
import Group from 'components/ui/Group';
import I18N from 'lib/I18N';
import Icon from 'components/ui/Icon';
import SelfServeSource from 'models/DataUploadApp/SelfServeSource';
import SelfServeSourceService from 'services/DataUploadApp/SelfServeSourceService';
import Toaster from 'components/ui/Toaster';
import useBoolean from 'lib/hooks/useBoolean';
import {
  DATE_TIME_FORMAT,
  FAILED_DATAPREP_STATUSES,
  RUNNING_DATAPREP_STATUSES,
} from 'components/DataUploadApp/constants';
import { getDataprepJobLink } from 'components/DataUploadApp/util';
import type { ActionCell_selfServeSource } from './__generated__/ActionCell_selfServeSource.graphql';

// NOTE(abby): Exporting the type from this file rather than the models file,
// so it can be colocated with the fragment it's using. Importing from generated
// graphql fragments in other files can lead to bugs if the name changes, but
// the import isn't updated.
// eslint-disable-next-line camelcase
export type DataUploadSource = ActionCell_selfServeSource;

type Props = {
  buildOnOpenModal: SelfServeSource => () => void,
  isSelfServeAdmin: boolean,
  queuedStatus: boolean,
  source: SelfServeSource,
  updateFailed: boolean,
};

export default function ActionCell({
  buildOnOpenModal,
  isSelfServeAdmin = false,
  queuedStatus,
  source,
  updateFailed,
}: Props): React.Node {
  const [
    deleteConfirmationVisible,
    showDeleteConfirmation,
    hideDeleteConfirmation,
  ] = useBoolean(false);

  const dataprepFlow = source.dataprepFlow();
  const latestDataprepJob = dataprepFlow?.latestDataprepJob();
  const getPillText = (): string | void => {
    if (dataprepFlow) {
      if (latestDataprepJob !== undefined) {
        if (FAILED_DATAPREP_STATUSES.has(latestDataprepJob?.status())) {
          return I18N.text('dataprep failed');
        }
        if (RUNNING_DATAPREP_STATUSES.has(latestDataprepJob?.status())) {
          return I18N.text('dataprep running');
        }
      }
    }

    if (queuedStatus) {
      return I18N.textById('queued');
    }
    if (updateFailed) {
      return I18N.text('update failed');
    }
    return undefined;
  };

  const renderActionContent = () => {
    const pillText = getPillText();
    const onOpenModal = buildOnOpenModal(source);

    // If there's no pill text it means the source is neither queued nor failed
    if (!pillText) {
      return (
        <Button intent={Button.Intents.PRIMARY} minimal onClick={onOpenModal}>
          {I18N.text('Update Data')}
        </Button>
      );
    }

    const pillClassNames = classNames('u-caption-text', {
      'data-status-table__queued-pill': queuedStatus,
      'data-status-table__update-failed-pill': updateFailed,
    });
    // The latest file summary should always be defined unless the database is in a bad state.
    // But since the foreign keys do not require the latest file summary be populated, falling
    // back to the source last modified guards against the bad state.
    const sourceLastModified = (
      source.latestFileSummary() || source.lastModified()
    ).format(DATE_TIME_FORMAT);
    return (
      <Group.Vertical spacing="none">
        <Group.Item className={pillClassNames} marginBottom="xs">
          {pillText}
        </Group.Item>
        {sourceLastModified}
        <Group.Item className="u-caption-text" marginBottom="s">
          {I18N.text('Date of submission')}
        </Group.Item>
        <Group.Horizontal spacing="s">
          <div
            className="data-status-table__action-link"
            onClick={onOpenModal}
            role="button"
          >
            {I18N.text('Edit setup')}
          </div>
          {isSelfServeAdmin && latestDataprepJob && (
            <a
              className="data-status-table__action-link"
              href={getDataprepJobLink(parseInt(latestDataprepJob.id(), 10))}
              rel="noreferrer noopener"
              target="_blank"
            >
              {I18N.text('See job')}
            </a>
          )}
        </Group.Horizontal>
      </Group.Vertical>
    );
  };

  const onDeleteSource = () => {
    hideDeleteConfirmation();
    SelfServeSourceService.delete(source.uri(), source).catch(() =>
      Toaster.error(I18N.textById('unknownError')),
    );
    analytics.track('Deleted data upload source', {
      source: source.sourceId(),
    });
  };

  const warningText = source.dataprepFlow()
    ? I18N.text(
        "By deleting source '%(sourceName)s', the source will be removed from Data Upload. The data will remain in the platform.",
        'deleteDataprepSourceConfirmation',
        { sourceName: source.pipelineDatasource().name() },
      )
    : I18N.text(
        "By deleting source '%(sourceName)s', the data will disappear from the platform. This action cannot be undone. If the data has already been integrated, it will be removed when the pipeline next runs.",
        'deleteCSVSourceConfirmation',
        { sourceName: source.pipelineDatasource().name() },
      );

  return (
    <React.Fragment>
      <Group.Horizontal alignItems="center" firstItemFlexValue={1} flex>
        {renderActionContent()}

        {isSelfServeAdmin && (
          <Group.Item
            className="data-status-table__delete-icon"
            marginRight="m"
          >
            <Icon
              ariaName={I18N.text('delete source')}
              onClick={showDeleteConfirmation}
              type="svg-trash-can"
            />
          </Group.Item>
        )}
      </Group.Horizontal>
      <DestructiveActionModal
        onActionAcknowledged={onDeleteSource}
        onActionCancelled={hideDeleteConfirmation}
        show={deleteConfirmationVisible}
        warningText={warningText}
      />
    </React.Fragment>
  );
}
