// @flow
import { List, Record } from 'immutable';
import type { RecordFactory } from 'immutable';

import ColumnMapping from 'models/DataUploadApp/ColumnMapping';
import Moment from 'models/core/wip/DateTime/Moment';
import type { ApiFileSummary } from 'models/DataUploadApp/types';

type DataUploadFileSummaryProps = {
  columnMapping: List<ColumnMapping>,
  lastModified: Moment,
};

const DataUploadFileSummaryRecord: RecordFactory<DataUploadFileSummaryProps> = Record(
  {
    columnMapping: undefined,
    lastModified: undefined,
  },
);

export default class DataUploadFileSummary extends DataUploadFileSummaryRecord {
  static deserialize(data: ApiFileSummary): DataUploadFileSummary {
    const { columnMapping, lastModified } = data;
    return new DataUploadFileSummary({
      columnMapping: List(columnMapping.map(ColumnMapping.deserialize)),
      lastModified: Moment.utc(lastModified),
    });
  }

  columnMapping(): List<ColumnMapping> {
    return this.get('columnMapping');
  }

  lastModified(): Moment {
    return this.get('lastModified');
  }

  setColumnMapping<T>(value: List<ColumnMapping>): T {
    return ((this.set('columnMapping', value): any): T);
  }

  setLastModified<T>(value: Moment): T {
    return ((this.set('lastModified', value): any): T);
  }
}
