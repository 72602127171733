// @flow
import { Record } from 'immutable';
import type { RecordFactory } from 'immutable';

import Moment from 'models/core/wip/DateTime/Moment';
import type { ApiDataprepJob } from 'models/DataUploadApp/types';

type DataprepJobProps = {
  id: number,
  lastModified: Moment,
  status: string,
};

const DataprepJobRecord: RecordFactory<DataprepJobProps> = Record({
  id: undefined,
  lastModified: undefined,
  status: undefined,
});

export default class DataprepJob extends DataprepJobRecord {
  static deserialize(data: ApiDataprepJob): DataprepJob {
    const { id, lastModified, status } = data;
    return new DataprepJob({
      id,
      status,
      lastModified: Moment.utc(lastModified),
    });
  }

  serialize(): ApiDataprepJob {
    return {
      id: this.id(),
      lastModified: this.lastModified().toISOString(),
      status: this.status(),
    };
  }

  id(): number {
    return this.get('id');
  }

  lastModified(): Moment {
    return this.get('lastModified');
  }

  status(): string {
    return this.get('status');
  }

  setId<T>(value: number): T {
    return ((this.set('id', value): any): T);
  }

  setLastModified<T>(value: Moment): T {
    return ((this.set('lastModified', value): any): T);
  }

  setStatus<T>(value: string): T {
    return ((this.set('status', value): any): T);
  }
}
