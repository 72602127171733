// @flow
import { List, Record } from 'immutable';
import type { RecordFactory } from 'immutable';

import DataUploadFileSummary from 'models/DataUploadApp/DataUploadFileSummary';
import DataprepFlow from 'models/DataUploadApp/DataprepFlow';
import Moment from 'models/core/wip/DateTime/Moment';
import PipelineDatasource from 'models/DataUploadApp/PipelineDatasource';
import { CSV_TYPE, DATAPREP_TYPE } from 'models/DataUploadApp/types';
import { RUNNING_DATAPREP_STATUSES } from 'components/DataUploadApp/constants';
import type { SerializedInputSelfServeSource } from 'services/DataUploadApp/SelfServeSourceService';
import type { SourceDateRange } from 'models/DataUploadApp/types';

type SelfServeSourceProps = {
  dataprepFlow: DataprepFlow | void,
  fileSummaries: List<DataUploadFileSummary> | void,
  id: number,
  lastModified: Moment,
  latestFileSummary: Moment | void,
  pipelineDatasource: PipelineDatasource,
  sourceId: string,
  sourceRange: SourceDateRange,
  unpublishedDimensionsCount: number,
  uri: string,
};

const SelfServeSourceRecord: RecordFactory<SelfServeSourceProps> = Record({
  dataprepFlow: undefined,
  fileSummaries: undefined,
  id: undefined,
  lastModified: undefined,
  latestFileSummary: undefined,
  pipelineDatasource: undefined,
  sourceId: undefined,
  sourceRange: undefined,
  unpublishedDimensionsCount: undefined,
  uri: undefined,
});

export default class SelfServeSource extends SelfServeSourceRecord {
  static deserialize(data: SerializedInputSelfServeSource): SelfServeSource {
    const {
      $uri,
      dataprepFlow,
      fileSummaries,
      id,
      lastModified,
      latestFileSummary,
      pipelineDatasource,
      sourceId,
      sourceRange,
      unpublishedDimensionsCount,
    } = data;

    return new SelfServeSource({
      id,
      sourceId,
      sourceRange,
      unpublishedDimensionsCount,
      dataprepFlow: dataprepFlow
        ? DataprepFlow.deserialize(dataprepFlow)
        : undefined,
      fileSummaries: fileSummaries
        ? List(fileSummaries.map(DataUploadFileSummary.deserialize))
        : undefined,
      lastModified: Moment.utc(lastModified).local(),
      latestFileSummary: latestFileSummary
        ? Moment.utc(latestFileSummary).local()
        : undefined,
      pipelineDatasource: PipelineDatasource.deserialize(pipelineDatasource),
      uri: $uri,
    });
  }

  serialize(): Object {
    const dataprepFlow = this.dataprepFlow();
    return {
      dataprepFlow: dataprepFlow ? dataprepFlow.serialize() : null,
      lastModified: this.lastModified().toISOString(),
      pipelineDatasource: {
        name: this.pipelineDatasource().name(),
        unpublishedFields: this.pipelineDatasource()
          .unpublishedFields()
          .map(unpublishedField => ({ id: unpublishedField.id() })),
      },
      sourceId: this.sourceId(),
      sourceRange: this.sourceRange(),
    };
  }

  id(): number {
    return this.get('id');
  }

  dataprepFlow(): DataprepFlow | void {
    return this.get('dataprepFlow');
  }

  fileSummaries(): List<DataUploadFileSummary> | void {
    return this.get('fileSummaries');
  }

  lastModified(): Moment {
    return this.get('lastModified');
  }

  latestFileSummary(): Moment | void {
    return this.get('latestFileSummary');
  }

  pipelineDatasource(): PipelineDatasource {
    return this.get('pipelineDatasource');
  }

  sourceId(): string {
    return this.get('sourceId');
  }

  sourceRange(): Object {
    return this.get('sourceRange');
  }

  unpublishedDimensionsCount(): number {
    return this.get('unpublishedDimensionsCount');
  }

  uri(): string {
    return this.get('uri');
  }

  integrationType(): string {
    if (this.get('dataprepFlow')) {
      return DATAPREP_TYPE;
    }
    return CSV_TYPE;
  }

  isLoaded(): boolean {
    let isLoaded = true;
    const dataprepFlow = this.dataprepFlow();
    if (dataprepFlow) {
      isLoaded = false;
      const latestDataprepJob = dataprepFlow.latestDataprepJob();
      if (latestDataprepJob) {
        isLoaded = !RUNNING_DATAPREP_STATUSES.has(latestDataprepJob.status());
      }
    }

    return isLoaded;
  }

  isQueued(lastPipelineRuntime: Moment | void): boolean {
    // A source is queued if the pipeline has not run since it's been modified. For dataprep
    // sources, also check if the pipeline has run since the dataprep finished running.
    if (this.latestFileSummary() === undefined) return false;
    const lastModified = this.latestFileSummary() || this.lastModified();

    const pipelineQueued = lastPipelineRuntime
      ? lastPipelineRuntime.isBefore(lastModified)
      : true;

    const dataprepFlow = this.dataprepFlow();
    if (dataprepFlow && lastPipelineRuntime) {
      const latestDataprepJob = dataprepFlow.latestDataprepJob();
      if (latestDataprepJob) {
        return (
          pipelineQueued ||
          RUNNING_DATAPREP_STATUSES.has(latestDataprepJob.status()) ||
          lastPipelineRuntime.isBefore(latestDataprepJob.lastModified())
        );
      }
    }

    return pipelineQueued;
  }

  setId<T>(value: number): T {
    return ((this.set('id', value): any): T);
  }

  setDataprepFlow<T>(value: DataprepFlow): T {
    return ((this.set('dataprepFlow', value): any): T);
  }

  setFileSummaries<T>(value: List<DataUploadFileSummary>): T {
    return ((this.set('fileSummaries', value): any): T);
  }

  setLatestFileSummary<T>(value: Moment): T {
    return ((this.set('latestFileSummary', value): any): T);
  }

  setLastModified<T>(value: Moment): T {
    return ((this.set('lastModified', value): any): T);
  }

  setPipelineDatasource<T>(value: PipelineDatasource): T {
    return ((this.set('pipelineDatasource', value): any): T);
  }

  setSourceId<T>(value: string): T {
    return ((this.set('sourceId', value): any): T);
  }

  setSourceRange<T>(value: Object): T {
    return ((this.set('sourceRange', value): any): T);
  }

  setUnpublishedDimensionsCount<T>(value: number): T {
    return ((this.set('unpublishedDimensionsCount', value): any): T);
  }

  setUri<T>(value: string): T {
    return ((this.set('uri', value): any): T);
  }
}
